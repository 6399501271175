@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 9%;
    --foreground: 150 14% 97%;
    --card: 0 0% 13%;
    --card-foreground: 150 14% 97%;
    --card-border: 0 0% 24%;
    --icon-bg: 0, 0, 16%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 0% 9%;
    --primary-foreground: 150 14% 97%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 73%;
    --muted-foreground: 0 0% 73%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --brand-green: 155, 89%, 34%;
    --brand-green-secondary: 120, 86%, 92%;
    --brand-green-hover: 155, 85.4%, 24.1%;
    --brand-green-outline-hover: 155, 20.8%, 12.7%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
  }

  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    margin: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  main {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    width: 100%;
    margin: auto;;
    overflow-y: auto;
  }
}

/* Custom scrollbar for WebKit browsers */
.scrollbar-custom::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.scrollbar-custom::-webkit-scrollbar-track {
  background: transparent; /* Background of the scrollbar track */
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  background: #2bb673; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}

.scrollbar-custom::-webkit-scrollbar-thumb:hover {
  background: #2bb673; /* Color when hovering over the scrollbar thumb */
}

/* Custom scrollbar for Firefox */
.scrollbar-custom {
  scrollbar-width: thin; /* Width of the scrollbar */
  scrollbar-color: #2bb673 transparent; /* Color of the scrollbar thumb and track */
}

/* Custom styles for combined range input slider */
.range-slider {
  position: relative;
  width: 100%;
}

.range-slider input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 8px;
  background: transparent;
  z-index: 2;
}

.range-slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #00B96C;
  cursor: pointer;
  border-radius: 50%;
  pointer-events: all;
  position: relative;
  z-index: 2;
}

.range-slider input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #00B96C;
  cursor: pointer;
  border-radius: 50%;
  pointer-events: all;
  position: relative;
  z-index: 2;
}

.range-slider .track {
  position: absolute;
  width: 100%;
  height: 8px;
  background: #a3a3a3;
  z-index: 1;
  border-radius: 4px;
}

.range-slider .range {
  position: absolute;
  height: 8px;
  background: #00B96C;
  z-index: 1;
  border-radius: 4px;
}


.heroHeading {
  color: #f7f9f8 !important;
  font-style: italic;
  font-weight: 800;
  line-height: 76px;
  letter-spacing: -0.005em;
  text-align: left;
  text-transform: uppercase;
}

.heading {
  color: #f7f9f8 !important;
  font-size: 48px;
  font-weight: 600;
  line-height: 67px;
  letter-spacing: -0.005em;
}

.typography {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.005em;
  color: #bebebe !important;
}

.card .heading {
  color: #f7f9f8 !important;
  font-size: 32px;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: -0.005em;
  text-align: left;
}

.navigationLink {
  color: #bebebe;
  text-transform: uppercase;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  padding: 10px 0;
}

.navigationLink:hover {
  color: #fff;
  text-decoration: none;
}

.button-primary {
  background: var(--button-background);
  border-radius: 8px;
  border: none;
  color: var(--button-text-color);
  padding: 8px 8px;
  width: 100%;
}

.card {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  width: 100%;
  overflow: hidden;
}

/* Add this CSS to your global stylesheet or a dedicated CSS file */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  z-index: 50;
}

.modal-content {
  background-color: #212121;
  margin: 15% auto;
}

.close {
  color: #b9b9b9;
  float: right;
  font-size: 24px;
  font-weight: thin;
}

.close:hover,
.close:focus {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.glow-effect::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;
  background: rgba(0, 255, 0, 0.2);
  box-shadow: 0 0 10px 5px rgba(0, 255, 0, 0.5);
  z-index: -1;
}


.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;  /* Chrome, Safari and Opera */
}